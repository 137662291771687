.popup-info__title {
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  margin: 43px auto 0 36px;
}

.popup-info__link-words {
  margin: 14px auto 51px 36px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: #2f71e5;
}
