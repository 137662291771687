.popup__input {
  padding: 9px 0 9px 0;
  color: #000000;
  border: 1px solid #000000;
  border-color: rgba(0, 0, 0, 0.2);
  border-top: none;
  border-right: none;
  border-left: none;
  margin: 0 auto;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  outline: none;
  caret-color: #2f71e5;
}

.popup__input:focus-visible {
  content: "";
  background-color: #f0f6ff;
}
.popup__input::after:selection {
  content: "";
  background-color: #56b4ef;
}

.popup__input:focus {
  border: 1px solid #56b4ef;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05),
    0 0 8px rgba(82, 168, 236, 0.6);
  -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05),
    0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05),
    0 0 8px rgba(82, 168, 236, 0.6);
}

.popup__input::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.popup__input-title {
  padding: 0;
  margin: 13px 0 0 0;
  color: #2f71e5;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}

.popup__input-title:first-child {
  margin-top: 0;
}

.popup__input-error-main {
  align-self: center;
  margin-top: 17px;
}

.popup__input-error {
  color: #ff0000;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}

.popup__input-error_visible {
  visibility: visible;
  display: block;
}
@media (max-width: 768px) {
  .popup__input {
    max-width: 288px;
    margin: 0 16px 30px 16px;
  }

  .popup__input:last-of-type {
    margin: 0 16px 23px 16px;
  }

  .popup__input-title {
    margin-left: 16px;
  }
}
