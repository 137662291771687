.search-form {
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  display: flex;
  padding: 0;
  flex-direction: row;
  margin: 88px auto 80px auto;
  width: 608px;
  background-color: #fff;
  height: 64px;
  border: 1px solid #d1d2d6;
  border-radius: 100px;
  border-style: none;
  backdrop-filter: blur(8px);
}

.search-form__input {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  align-self: center;
  margin: 0 auto 0 24px;
  width: 376px;
  height: 22px;
  border-radius: 100px;
  border: none;
  color: #b6bcbf;
  outline: none;
  caret-color: #2f71e5;
  z-index: 0;
}

.search-form__input:focus {
  color: #1a1b22;
}

.search-form__button {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 0;
  margin: 0;
  right: 0;
  min-width: 168px;
  height: 64px;
  border-radius: 100px;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #2f71e5;
  box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
}

.search-form__button:hover {
  background-color: #347eff;
}

.search-form__button:active {
  background-color: #2a65cc;
}

@media (min-width: 637px) and (max-width: 820px) {
  .search-form {
    max-width: 452px;
    height: 56px;
  }
  .search-form__input {
    max-width: 448px;
  }
  .search-form__button {
    min-width: 160px;
    height: 56px;
  }
}

@media (max-width: 636px) {
  .search-form {
    background-color: transparent;
    width: 288px;
    height: 128px;
    display: inline;
    margin: 122px auto 32px auto;
  }
  .search-form__input {
    padding: 17px 16px 17px 16px;
    margin: 0;
    width: 288px;
    height: 56px;
  }
  .search-form__button {
    margin-top: 16px;
    width: 288px;
    height: 56px;
  }
}
