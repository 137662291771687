.popup__title {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  margin: 34px auto 0 36px;
  color: #1a1b22;
}

.popup__form {
  display: flex;
  flex-direction: column;
  max-width: 358px;
  width: 100%;
  margin: 22px auto 0 auto;
  align-self: center;
  justify-self: center;
  font-family: "Inter";
}

.popup__save-button {
  margin: 8px 0 0 0;
  background-color: #2f71e5;
  box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
  border: none;
  border-radius: 100px;
  color: #fff;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  width: 360px;
  height: 64px;
}

.popup__save-button:hover {
  background-color: #347eff;
}

.popup__save-button:active {
  background-color: #2a65cc;
}

.popup__save-button_disabled {
  background: #e6e8eb;
  color: #b6bcbf;
  pointer-events: none;
  box-shadow: none;
}

.popup__link {
  align-self: center;
  padding: 0;
  margin: 16px auto 28px auto;
  max-height: 20px;
  color: #000000;
}
.popup__form-error {
  text-align: center;
  margin-top: 10px;
}
.popup__link-words {
  text-decoration: none;
  color: #2f71e5;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

@media (max-width: 636px) {
  title.popup__title {
    margin: 16px auto 0 0;
  }

  .popup__title {
    margin: 34px auto 0 16px;
  }

  .popup__form {
    height: 560px;
    min-width: 288px;
    margin: 17px 0 0 0;
  }
  .popup__input-title {
    margin: 0 0 30px 0;
  }

  .popup__input {
    width: 288px;
    padding: 0;
    margin: 0;
  }

  .popup__save-button {
    width: 288px;
    align-self: center;
  }

  .popup__link {
    height: 20px;
  }
}
