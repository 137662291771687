.card {
  width: 400px;
  max-width: 100%;
  height: 576px;
  border-radius: 20px;
  background-color: #fff;
  margin: 0 auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.card__image {
  background-image: url("../../images/image_01.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 272px;
  display: flex;
}

.card__keyword {
  opacity: 1;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  width: auto;
  height: 40px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  padding: 0 22px;
  margin-left: 24px;
}

.card__keyword_hidden {
  opacity: 0;
}

.card__icon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  position: fixed;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  color: #b6bcbf;
  border: none;
  padding: 0;
  margin-top: 24px;
  margin-right: 24px;
  right: 0;
  cursor: pointer;
}

.card__icon.card__icon_button_trash {
  background-image: url("../../images/Trash.svg");
}

.card__icon.card__icon_button_flag {
  background-image: url("../../images/unmarked-bkm.svg");
}

.card__icon.card__icon_button_flag:hover {
  background-image: url("../../images/hover-bkm.svg");
}

.card__icon-hover {
  display: none;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  width: 182px;
  height: 40px;
  border: none;
  border-radius: 10px;
  position: fixed;
  background-color: #fff;
  color: #000;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  margin-top: 24px;
  margin-right: 69px;
  box-shadow: 0px 10px 22px rgba(59, 74, 116, 0.1);
}

.card__icon_button_trash:hover .card__icon-hover {
  display: flex;
}
.card__icon_button_flag:hover .card__icon-hover {
  display: flex;
}
.card__icon_button_trash:hover {
  background-image: url("../../images/Trash_active.svg");
}

.card__icon-hover_visible {
  display: block;
}

.card__icon_button_flag_active {
  background-image: url("../../images/filled-bkm.svg");
}

.card__date {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #b6bcbf;
  max-width: 146px;
  margin: 20px 0 0 24px;
  padding: 0;
}

.card__title {
  font-family: "Roboto Slab", sans-serif;
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;
  color: #1a1b22;
  max-width: 352px;
  height: 60px;
  margin: 12px auto 0 auto;
  padding: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card__text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #1a1b22;
  width: 352px;
  height: 110px;
  padding: 0;
  margin: 16px auto 18px auto;
}
.card__source {
  font-family: "Roboto Slab", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #b6bcbf;
  max-width: 352px;
  margin: 0 auto;
  position: relative;
  padding: 0;
  margin: 0 24px 24px 24px;
}

@media (min-width: 768px) and (max-width: 1020px) {
  .card {
    width: 224px;
    height: 420px;
  }
  .card__image {
    height: 150px;
  }
  .card__keyword {
    margin-top: 8px;
    margin-left: 8px;
  }
  .card__icon {
    margin-top: 8px;
    margin-right: 8px;
  }
  .card__date {
    padding: 0;
    max-width: 148px;
    margin-top: 16px;
    margin-left: 16px;
  }
  .card__title {
    font-size: 22px;
    line-height: 24px;
    width: 192px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 72px;
    margin-top: 12px;
  }
  .card__text {
    width: 192px;
    height: 88px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-top: 10px;
  }
  .card__source {
    width: 192px;
    height: 20px;
    margin: 12px 16px 0 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0 16px 16px 16px;
  }
  .card__icon-hover {
    margin-top: 8px;
    margin-right: 53px;
    width: 158px;
  }
}

@media (max-width: 767px) {
  .card {
    width: 288px;
    height: 440px;
  }
  .card__image {
    height: 196px;
  }
  .card__keyword {
    margin-top: 16px;
    margin-left: 16px;
  }
  .card__icon {
    margin-top: 16px;
    margin-right: 16px;
  }
  .card__date {
    max-width: 256px;
    margin: 16px 0 0 16px;
  }
  .card__title {
    width: 256px;
    font-size: 22px;
    line-height: 24px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 10px auto 0 auto;
    height: 48px;
  }
  .card__text {
    width: 256px;
    height: 88px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin: 14px auto 8px auto;
  }
  .card__source {
    width: 256px;
    margin: 0 16px 16px 16px;
  }
  .card__icon-hover {
    margin-top: 16px;
    margin-right: 61px;
    width: fit-content;
    padding: 0 20px;
  }
}
