.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto 0 auto;
}

.header__title {
  margin: 80px auto 0 auto;
  padding: 0;
  font-family: "Roboto Slab", sans-serif;
  font-weight: 400;
  font-size: 60px;
  line-height: 64px;
  color: #fff;
  max-width: 608px;
  max-height: 128px;
}

.header__text {
  margin: 32px auto 0 auto;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  max-width: 608px;
  height: auto;
}

@media (min-width: 637px) and (max-width: 820px) {
  .header {
    margin: 0 auto;
    max-width: 768px;
  }
  .header__title {
    font-size: 36px;
    line-height: 40px;
    max-width: 452px;
    margin: 38px auto 0 auto;
  }
  .header__text {
    max-width: 452px;
    height: 48px;
    margin: 16px auto 0 auto;
  }
}

@media (max-width: 636px) {
  .header {
    width: 320px;
    margin: 0 auto;
    z-index: 500;
  }
  .header__title {
    max-width: 285px;
    font-size: 36px;
    line-height: 40px;
    font-weight: 400;
    margin: 30px auto 0 auto;
  }
  .header__text {
    max-width: 288px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400px;
  }
}
