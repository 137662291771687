.about {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  width: 100%;
  max-height: 100%;
  background-color: #fff;
  margin: 0 auto 0 0;
  padding: 80px 0;
}

.about__avatar {
  background-image: url("../../images/me.jpeg");
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: contain;
  border-radius: 500px;
  width: 100%;
  height: auto;
  width: 464px;
  height: 464px;
  display: flex;
  position: relative;
  justify-content: center;
  margin: 0 0 0 104px;
  padding: 0;
}

.about__block {
  max-width: 600px;
  max-height: 190px;
  margin: 51px auto 0 0;
  color: #1a1b22;
}

.about__title {
  font-family: "Roboto Slab", sans-serif;
  font-size: 40px;
  line-height: 46px;
  font-weight: 400;
  text-align: left;
  width: 600px;
  padding: 0;
  margin: 0 auto 0 56px;
}

.about__text {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  width: 600px;
  padding: 0;
  margin: 24px auto 0 56px;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .about {
    padding: 61px 0;
    margin: 0 auto;
  }
  .about__avatar {
    width: 232px;
    height: 232px;
    margin: 0 0 0 auto;
  }
  .about__block {
    margin: 0 auto 0 0;
    max-width: 424px;
  }
  .about__title {
    font-size: 30px;
    line-height: 40px;
    margin: -21px auto 0 32px;
    max-width: 424px;
  }
  .about__text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    max-width: 424px;
    margin: 16px auto 0 32px;
  }
}

@media (max-width: 767px) {
  .about {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  .about__avatar {
    width: 272px;
    height: 272px;
    margin: 0;
  }
  .about__block {
    max-width: 288px;
    margin: 24px auto 128px auto;
  }
  .about__title {
    margin: 0 auto;
    max-width: 288px;
    height: 40px;
    font-size: 30px;
  }
  .about__text {
    margin: 16px auto 0 auto;
    max-width: 288px;
  }
}
