.news-list {
  background-color: #f5f6f7;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fill, 400px);
  justify-content: center;
  column-gap: 16px;
  row-gap: 16px;
  padding: 62px 102px;
}

@media (min-width: 768px) and (max-width: 1020px) {
  .news-list {
    grid-template-columns: repeat(3, 224px);
    gap: 8px;
    margin: 0 auto;
    padding: 32px 40px;
  }
}

@media (min-width: 638px) and (max-width: 767px) {
  .news-list {
    grid-template-columns: repeat(2, 288px);
    max-width: 100vw;
    min-width: 320px;
    column-gap: 10px;
    padding: 58px 0 24px 0;
  }
}
