.app {
  font-family: sans-serif;
  max-width: 1440px;
  margin: 0 auto;
  background: #fff;
  color: #1a1b22;
  margin: 0 auto;
}

.app_theme_dark {
  background-image: url(../../images/bg-image.jpg);
  background-repeat: no-repeat;
  background-position-x: center;
  color: #fff;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .app_theme_dark {
    max-width: 100vw;
    margin: 0;
  }
}

/* * {
  box-sizing: border-box;
} */
