.nothing-found {
  max-width: 1440px;
  margin: 0 auto;
  background-color: #f5f6f7;
  display: flex;
  padding: 86px 0 80px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nothing-found__title {
  font-family: "Roboto Slab", sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 30px;
  color: #1a1b22;
  padding: 0;
  margin: 24px 0 0 0;
}

.nothing-found__text {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  color: #b6bcbf;
  padding: 0;
  margin-top: 16px;
}
