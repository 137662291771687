.circle {
  margin: 0 auto;
  padding: 80px 0;
  min-height: 128px;
  background-color: #f5f6f7;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}
.circle-preloader {
  display: block;
  width: 74px;
  height: 74px;
  border: 1px solid #1a1b22;
  border-bottom-color: #d1d2d600;
  mask-border-source: conic-gradient(
    from 180deg,
    #1a1b22 0deg,
    #d1d2d600 360deg
  );
  border-radius: 50%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  animation: spin 0.75s infinite linear;
  animation-direction: reverse;
}

.circle__text {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #b6bcbf;
  align-self: center;
  padding-top: 24px;
  margin: 0 auto;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
