.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  position: fixed;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: visibility 0ms 400ms, opacity 400ms;
}

.popup__content {
  background-color: #fff;
  position: relative;
  margin: 0px auto;
  width: 430px;
  padding: 0;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.popup__close-button {
  border: none;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  align-self: flex-end;
  justify-self: flex-end;
  margin-top: -40px;
  margin-right: -40px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  right: 0;
  top: 0;
  background-image: url(../../images/back.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.popup__is-opened {
  visibility: initial;
  pointer-events: all;
  opacity: 1;
  transition: visibility 0ms, opacity 400ms;
}

@media (max-width: 636px) {
  .popup {
    max-width: 768px;
    margin: 0 auto;
    max-height: fit-content;
    padding: 0;
  }
  .popup__content {
    margin: 56px;
    max-width: 320px;
  }
  button.popup__close-button {
    width: 24px;
    height: 24px;
    position: relative;
    margin-top: -40px;
    margin-right: 0;
  }
}
