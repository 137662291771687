.header-news {
  padding-top: 40px;
  padding-left: 104px;
  background-color: #fff;
  padding-bottom: 56px;
  margin: 0 auto 0 0;
}

.header-news__name {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #1a1b2280;
  opacity: 50%;
  margin: 0 auto;
}

.header-news__info {
  font-family: "Roboto Slab", sans-serif;
  font-size: 40px;
  line-height: 46px;
  font-weight: 400;
  color: #1a1b22;
  max-width: 530px;
  margin: 0;
  padding-top: 28px;
  padding-bottom: 30px;
}

.header-news__keywords {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #1a1b22;
  max-width: 712px;
  margin: 0;
}

.header-news__keywords_bold {
  font-weight: 700;
}

@media (min-width: 638px) and (max-width: 848px) {
  .header-news {
    width: 712px;
    padding: 0;
    margin: 24px auto 32px 40px;
  }
  .header-news__name {
    font-size: 14px;
    line-height: 24px;
  }
  .header-news__info {
    max-width: 336px;
    font-size: 30px;
    line-height: 34px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media (max-width: 637px) {
  .header-news {
    max-width: 288px;
    min-width: 100vw;
    padding: 8px 16px 32px 16px;
    margin: 0 auto;
    padding: 8px 0 32px 0;
  }
  .header-news__name {
    font-size: 12px;
    width: 288px;
  }
  .header-news__info {
    width: 288px;
    font-size: 30px;
    line-height: 34px;
    padding-top: 16px;
    padding-bottom: 50px;
    margin: 0 auto;
  }
  .header-news__keywords {
    width: 288px;
    margin: 0 auto;
  }
}
