.footer {
  max-width: 1440px;
  margin: 0 106px;
  padding: 30px auto 28px auto;
  display: flex;
  min-width: fit-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #ffffff;
}

.footer__text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #b6bcbf;
  position: absolute;
  left: 0;
  margin: 0 383px 0 auto;
}

.footer__links-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0 auto;
}

.footer__links {
  list-style-type: none;
  display: flex;
  margin: 0 0 0 auto;
  flex-direction: row;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: none;
}

.footer__link {
  width: 112px;
  text-decoration: none;
  color: #1a1b22;
}

.footer__link_yandex {
  text-decoration: none;
  color: #1a1b22;
  margin-left: 20px;
}

.footer__icons {
  display: flex;
  flex-direction: row;
  margin: 0 auto 0 0;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}

.footer__icon {
  margin-left: 26px;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .footer {
    padding: 21px 0 21px 0;
    max-width: 687px;
    margin: 0 auto;
  }
  .footer__links {
    margin: 0 auto 0 332px;
    padding: 0;
    width: 264px;
    height: 22px;
  }
  .footer__link {
    max-width: 88px;
  }
  .footer__links-block {
    align-items: center;
    margin: 0 4auto;
  }
  .footer__text {
    margin: 0 auto 0 0;
  }
  .footer__icons {
    margin-left: 24px;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .footer {
    flex-direction: column;
    margin: 20px auto 22px auto;
    width: 320px;
  }

  .footer__text {
    margin: 0 16px 22px 16px;
    bottom: 0;
  }

  .footer__links-block {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 16px 82px 16px;
    justify-content: space-between;
    align-items: flex-start;
  }
  .footer__links {
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: left;
    line-height: 48px;
    width: 200px;
    height: 74px;
  }
  .footer__link_yandex {
    margin: 0;
  }
  .footer__icons {
    margin: 0;
    padding: 0;
  }
}

.popup__link-words {
  text-decoration: none;
  color: #2f71e5;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}
