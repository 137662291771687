@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('./inter-v12-latin-regular.woff') format('woff'),
    url('./inter-v12-latin-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('./inter-v12-latin-500.woff') format('woff'),
    url('./inter-v12-latin-500.woff2') format('woff2');
}
