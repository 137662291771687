.search-results {
  background: #f5f6f7;
  margin: 0 auto;
  padding: 0 0 80px 0;
  height: fit-content;
}

.search-results__title {
  margin: 0 auto 0 104px;
  padding: 80px 0 2px 0;
  font-family: "Roboto Slab", sans-serif;
  font-size: 40px;
  line-height: 46px;
  font-weight: 400;
  color: #1a1b22;
}

.search-results__overview {
  margin-bottom: 64px;
}

.search-results__button {
  background: #ffffff;
  cursor: pointer;
  border-style: none;
  border-radius: 80px;
  width: 288px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px auto 0 auto;
  padding: 0;
  color: #1a1b22;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  grid-template-columns: repeat(auto-fit, 400px);
}

.search-results__button:hover {
  background-color: #e8e8e8;
}
.search-results__button_hidden {
  display: none;
}
@media (min-width: 1107px) and (max-width: 1437px) {
  .search-results__title {
    margin: 0 auto 0 204px;
  }
}

@media (min-width: 912px) and (max-width: 1106px) {
  .search-results__title {
    font-size: 40px;
    line-height: 46px;
  }
}

@media (min-width: 768px) and (max-width: 1020px) {
  .search-results__title {
    font-size: 30px;
    line-height: 34px;
    margin: 0 auto 0 40px;
  }
}

@media (min-width: 768px) and (max-width: 1106px) {
  .search-results {
    padding: 0 0 40px 0;
  }
  .search-results__overview {
    margin-bottom: 32px;
  }
}

@media (max-width: 767px) {
  .search-results {
    max-width: 768px;
    width: 100vw;
    padding: 0 0 24px 0;
    margin: 0 auto;
  }
  .search-results__overview {
    margin-bottom: 21px;
  }
  .search-results__title {
    max-width: 288px;
    padding: 32px 0 2px 0;
    font-size: 30px;
    line-height: 34px;
    text-align: left;
    margin: 0 auto;
  }
}
